<template>
  <div
    class="col-md-4 col-sm-12 ciasno-game-notifications csn-game-notification"
  >
    <div class="csn-game-notification-list-container">
      <slider
        ref="slider"
        :options="options"
        class="csn-game-notification-list-slider"
      >
        <slideritem
          v-for="(gameNotification, index) in GAME_NOTIFICATION_LIST"
          :key="index"
          :style="gameNotification.style"
          class="csn-game-notification-slider"
        >
          <component
            :is="gameNotification.icon"
            class="csn-game-notification-slider-icon"
          ></component>
          <div class="box">
            <div class="game-name">
              <Route :to="`${gamePlayPrefix}${gameNotification.gameUrl}`">
                {{ gameNotification.gameName }}
              </Route>
            </div>
            <div class="description" v-html="gameNotification.title"></div>
          </div>
        </slideritem>
        <div slot="loading">
          <div class="csn-game-notification-loader-container">
            <Loader />
          </div>
        </div>
      </slider>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { slider, slideritem } from 'vue-concise-slider'
import {
  Module,
  GAME_NOTIFICATION_LIST,
  GAME_NOTIFICATIONS,
  Digit,
} from '@/constants'

const options = {
  currentPage: Digit.NOUGHT,
  direction: 'vertical',
  infinite: Digit.FOUR,
  slidesToScroll: Digit.ONE,
  autoplay: Digit.TWO_THOUSAND,
  loop: true,
  tracking: false,
  thresholdDistance: Digit.ONE_HUNDRED_THIRTY,
  pagination: false,
}

export default {
  name: GAME_NOTIFICATIONS,
  components: {
    slider,
    slideritem,
    Loader: () => import('@/components/Loader'),
    Route: () => import('@/components/Route'),
    'bonuses-icon': () => import('@/components/svg/Bonuses-Icon'),
    'icon-cash-icon': () => import('@/components/svg/Cash-Icon.vue'),
    'free-spins-icon': () => import('@/components/svg/Free-Spins-Icon'),
    'show-all-icon': () => import('@/components/svg/Show-All-Icon'),
  },
  computed: {
    ...mapState(Module.GAME_NOTIFICATION, [GAME_NOTIFICATION_LIST]),
    options: () => options,
    gamePlayPrefix: () => 'game/play/',
  },
}
</script>
